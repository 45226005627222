import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { CallToAction } from "../components/CallToAction"
import { Languages } from "../provider"
import { useLang } from "../components/Header/LanguageToggle/useLang"
import { LinkedInIcon } from "../components/atoms/LinkedInIcon"
import { EmailIcon } from "../components/atoms/EmailIcon"

import { get, navigateToPrevOrDefault } from "../utils"
import { remarkForm, DeleteAction } from "gatsby-tinacms-remark"
import { Recommendations } from "../components/Recommendations"
import Awards from "../components/Awards"
import { useIsMobile } from "../components/hooks/useDimensions"
import { BG } from "../components/atoms/Section"
import { EmailType } from "../utils/sendEmail"
import { Button } from "../components/atoms/Button"
import { NoteField } from "../templateComponents/NoteComponent"
import { VPImage } from "../components/VPImage"

interface Props {
  data: any
  pageContext: any
  location: any
}

interface Award {
  year: number
  sectorEN: string
  sectorJP: string
  titleEN: string
  titleJP: string
  awardOrganizationEN: string
  awardOrganizationJP: string
}

interface Recommendation {
  lang: Languages
  author: string
  text: string
}

interface Paragraph {
  description: string
}

const DescriptionText = (frontmatter: any) => ({
  [Languages.EN]: frontmatter.descriptionEN?.map((paragraph: Paragraph) => (
    <p className="font-serif">{paragraph.description}</p>
  )),
  [Languages.JP]: frontmatter.descriptionJP?.map((paragraph: Paragraph) => (
    <p className="font-serif">{paragraph.description}</p>
  )),
})

const ConsultantTemplate: React.FC<Props> = props => {
  const lang = useLang()
  const isMobile = useIsMobile()
  const post = props.data.markdownRemark
  const siteTitle = props.data.site.siteMetadata.title
  const { previous, next } = props.pageContext

  const description = DescriptionText(post.frontmatter)[lang]

  const fluid = post.frontmatter.image?.childImageSharp?.fluid
  const awards = {
    [Languages.EN]: post.frontmatter.awards?.map((awardData: Award) => ({
      year: awardData.year,
      sector: awardData.sectorEN,
      title: awardData.titleEN,
      awardOrganization: awardData.awardOrganizationEN,
    })),
    [Languages.JP]: post.frontmatter.awards?.map((awardData: Award) => ({
      year: awardData.year,
      sector: awardData.sectorJP,
      title: awardData.titleJP,
      awardOrganization: awardData.awardOrganizationJP,
    })),
  }
  // TODO: filter for language
  // https://github.com/panzerstadt-dev/the-initiative-vp-web/issues/31
  const recommendations = post.frontmatter.recommendations
  // const recommendations = post.frontmatter.recommendations.filter(
  //   (rec: Recommendation) => rec.lang === lang
  // )

  const handleNav = () => navigateToPrevOrDefault("/about")

  return (
    <Layout location={props.location}>
      <SEO
        title={post.frontmatter.consultant}
        description={post.frontmatter.description || post.excerpt}
      />
      <div className="w-screen bg-vp-lightgray">
        <article className="p-6 xs:p-10 sm:max-w-6xl sm:mx-auto">
          <div
            className="flex content-between h-full"
            style={{
              display: "grid",
              gridTemplateColumns: isMobile ? "100%" : "1fr 2fr",
              gridGap: "1.5rem",
            }}
          >
            <VPImage fluid={fluid} />
            <div className="sm:px-6 sm:mr-6">
              <h1 className="max-w-lg text-3xl font-black tracking-tight text-vp-blue">
                {post.frontmatter.consultant}
              </h1>
              <p
                className="mb-4 text-base italic text-gray-600 leading-tighter"
                style={{ fontSize: 13 }}
              >
                {`${post.frontmatter.position}, ${post.frontmatter.location}`}
              </p>
              <section className="text-base leading-tight text-gray-800 override-markdown-headings override-list-style override-paragraph">
                {description}
              </section>
              {!isMobile && (
                <div className="flex mt-2">
                  <LinkedInIcon
                    to={
                      post.frontmatter.linkedIn ||
                      "https://www.linkedin.com/company/vantagepoint-k-k-/"
                    }
                  />
                  <div className="ml-2">
                    <EmailIcon
                      to={`mailto:${post.frontmatter.email ||
                        "info@vpointcareers.com"}`}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </article>

        {recommendations && recommendations.length > 0 && (
          <Recommendations recommendationsList={recommendations} />
        )}

        {awards && awards[lang] && awards[lang].length > 0 && (
          <Awards bg={BG.gray} awardsList={awards[lang]} smallTitle />
        )}

        <nav className="px-6 pb-16 xs:px-10 sm:px-16">
          <Button text="BACK" onClick={handleNav} />
        </nav>
      </div>
      <CallToAction
        title="Need Help?"
        text={
          lang === Languages.EN
            ? "Enter your details and we will get in touch soon."
            : "お問合せ内容のご記入をお願いします。"
        }
        type={EmailType.default}
      />
    </Layout>
  )
}

/**
 * This object defines the form for editing blog post.
 */
const ConsultantForm = {
  label: "Edit Consultant",
  actions: [DeleteAction],
  /**
   * The list of fields tell us what the form looks like.
   */
  fields: [
    /**
     * This is a field definition. There are many types of
     * components available, including:
     *
     * * text
     * * textarea
     * * toggle
     * * date
     * * markdown
     * * color
     * * group
     * * group-list
     * * blocks
     */
    {
      //
      name: "frontmatter.consultant",
      component: "text",
      label: "Name",
      required: true,
    },
    {
      name: "frontmatter.position",
      component: "text",
      label: "Current Role",
      required: true,
    },
    {
      name: "frontmatter.location",
      component: "text",
      label: "Location",
      description: "Office Location",
      defaultValue: "Tokyo",
    },
    {
      name: "rawFrontmatter.image",
      component: "image",
      label: "Image",
      required: true,
      parse: (filename: string) => filename,
      previewSrc: (formValues: any, { input }: { input: any }) => {
        const path = input.name.replace("rawFrontmatter", "frontmatter")
        const gatsbyImageNode = get(formValues, path) as any
        if (!gatsbyImageNode) return ""

        //specific to gatsby-image
        return gatsbyImageNode.childImageSharp.fluid.src
      },
      uploadDir: (blogPost: any) => {
        let postPathParts = blogPost.fileRelativePath.split("/")

        let postDirectory = postPathParts
          .splice(0, postPathParts.length - 1)
          .join("/")

        return postDirectory
      },
    },
    {
      name: "frontmatter.date",
      component: "date",
      label: "Date Joined",
      description:
        "changing this date will affect the order of the Consultant List",
    },
    {
      name: "frontmatter.linkedIn",
      component: "text",
      label: "LinkedIn",
      required: true,
    },
    {
      name: "frontmatter.email",
      component: "text",
      label: "Email",
      required: true,
    },
    // {
    //   name: "rawMarkdownBody",
    //   component: "markdown",
    //   label: "TO BE REMOVED",
    //   description:
    //     "use 'Heading' to create subtitles. all headings have been set to the same size",
    // },
    {
      name: "frontmatter.descriptionEN",
      component: "group-list",
      label: "Description (EN)",
      description: "each item is one paragraph.",
      itemProps: (item: any) => ({
        key: item.id,
        label: item.description,
      }),
      fields: [
        {
          name: "description",
          component: "textarea",
          label: "Paragraph",
        },
      ],
    },
    {
      name: "frontmatter.descriptionJP",
      component: "group-list",
      label: "Description (JP)",
      description: "each item is one paragraph.",
      itemProps: (item: any) => ({
        key: item.id,
        label: item.description,
      }),
      fields: [
        {
          name: "description",
          component: "textarea",
          label: "Paragraph",
        },
      ],
    },
    {
      name: "frontmatter.recommendations",
      component: "group-list",
      label: "Recommendations",
      itemProps: (item: any) => ({
        key: item.id,
        label: item.author,
      }),
      defaultItem: () => ({
        name: "New Author",
        id: Math.random()
          .toString(36)
          .substr(2, 9),
      }),
      fields: [
        {
          name: "lang",
          component: "select",
          label: "Language",
          options: [Languages.EN, Languages.JP],
          defaultValue: Languages.EN,
          description:
            "This Recommendation wil only be shown on the language selected",
        },
        {
          name: "text",
          label: "Text",
          component: "textarea",
        },
        {
          name: "author",
          label: "Author",
          component: "text",
        },
      ],
    },
    {
      name: "frontmatter.awards",
      component: "group-list",
      label: "Awards",
      itemProps: (item: any) => ({
        key: item.id,
        label: item.titleEN,
      }),
      defaultItem: () => ({
        titleEN: "New Award",
        id: Math.random()
          .toString(36)
          .substr(2, 9),
      }),
      fields: [
        {
          name: "year",
          label: "Year of Award",
          component: "number",
          defaultValue: new Date().getFullYear(),
        },
        {
          name: "sectorEN",
          label: "Sector (EN)",
          component: "text",
        },
        {
          name: "sectorJP",
          label: "Sector (JP)",
          component: "text",
        },
        {
          name: "titleEN",
          label: "Award Title (EN)",
          component: "text",
        },
        {
          name: "titleJP",
          label: "Award Title (JP)",
          component: "text",
        },
        {
          name: "awardOrganizationEN",
          label: "Award Organization (EN)",
          component: "text",
        },
        {
          name: "awardOrganizationJP",
          label: "Award Organization (JP)",
          component: "text",
        },
      ],
    },
    {
      name: "warning",
      component: NoteField,
    },
  ],
}

/**
 * The `remarkForm` higher order component wraps the `BlogPostTemplate`
 * and generates a new form from the data in the `markdownRemark` query.
 */
export default remarkForm(ConsultantTemplate, ConsultantForm)

// https://www.christopherbiscardi.com/post/accessing-frontmatter-through-interfaces/
export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      ...TinaRemark
      frontmatter {
        consultant

        date(formatString: "MMMM DD, YYYY")
        descriptionEN {
          description
        }
        descriptionJP {
          description
        }
        position
        location
        linkedIn
        email
        image {
          childImageSharp {
            fluid(quality: 100, maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        recommendations {
          # lang
          author
          text
        }
        awards {
          year
          sectorEN
          sectorJP
          titleEN
          titleJP
          awardOrganizationEN
          awardOrganizationJP
        }
      }
    }
  }
`

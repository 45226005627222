import React from "react"
import { motion } from "framer-motion"
import style from "./index.module.css"
import { vpBlue } from "../../../assets/colors"

const transitionDuration = 0.3

export const PlusIcon: React.FC<{
  hovered?: boolean
  invertColor?: boolean
  width?: number
  minus?: boolean
}> = ({ hovered, invertColor, width, minus }) => {
  const blue = vpBlue
  const white = "#fff"

  const circleVariant = {
    initial: { fill: invertColor ? white : blue },
    hovered: { fill: invertColor ? blue : white },
  }

  const lineVariant = {
    initial: { stroke: invertColor ? blue : white },
    hovered: { stroke: invertColor ? white : blue },
  }

  const blueBG = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 33.71 33.71"
      width={width}
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="LAYOUT">
          <circle
            className="fill-current text-vp-blue group-hover:text-white"
            style={{
              stroke: white,
              strokeMiterlimit: 10,
              strokeWidth: 1.03,
              transition: "all 300ms ease",
            }}
            cx="16.86"
            cy="16.86"
            r="16.34"
          />
          <line
            className="text-white stroke-current group-hover:text-vp-blue"
            style={{
              opacity: minus ? 0 : 1,
              strokeMiterlimit: 10,
              strokeWidth: 1.03,
              transition: "all 300ms ease",
            }}
            x1="16.86"
            y1="10.73"
            x2="16.86"
            y2="22.98"
          />
          <line
            className="text-white stroke-current group-hover:text-vp-blue"
            style={{
              strokeMiterlimit: 10,
              strokeWidth: 1.03,
              transition: "all 300ms ease",
            }}
            x1="10.73"
            y1="16.86"
            x2="22.98"
            y2="16.86"
          />
        </g>
      </g>
    </svg>
  )

  const whiteBG = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 33.71 33.71"
      width={width}
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="LAYOUT">
          <circle
            className="text-white fill-current group-hover:text-vp-blue"
            style={{
              stroke: invertColor ? blue : white,
              strokeMiterlimit: 10,
              strokeWidth: 1.03,
              transition: "all 300ms ease",
            }}
            cx="16.86"
            cy="16.86"
            r="16.34"
          />
          <line
            className="stroke-current text-vp-blue group-hover:text-white"
            style={{
              opacity: minus ? 0 : 1,
              strokeMiterlimit: 10,
              strokeWidth: 1.03,
              transition: "all 300ms ease",
            }}
            x1="16.86"
            y1="10.73"
            x2="16.86"
            y2="22.98"
          />
          <line
            className="stroke-current text-vp-blue group-hover:text-white"
            style={{
              strokeMiterlimit: 10,
              strokeWidth: 1.03,
              transition: "all 300ms ease",
            }}
            x1="10.73"
            y1="16.86"
            x2="22.98"
            y2="16.86"
          />
        </g>
      </g>
    </svg>
  )

  return invertColor ? whiteBG : blueBG
}

export const config = {
  dev: {
    email: {
      debug: "https://nzh7arvkq1.execute-api.us-east-1.amazonaws.com/dev/hello",
      url: "https://nzh7arvkq1.execute-api.us-east-1.amazonaws.com/dev/contact",
    },
  },
  prod: {
    email: {
      debug:
        "https://qhjcbyqcrf.execute-api.us-east-1.amazonaws.com/prod/hello",
      url:
        "https://bsi12vxzld.execute-api.us-east-1.amazonaws.com/prod/contact",
    },
  },
}

import React from "react"
import { Link } from "gatsby"

export const Disclaimer = () => {
  return (
    <small className="mt-4 text-gray-500">
      By submitting your details you agree to our <br />
      <Link className="text-white underline" to="/privacy-policy">
        Terms and Conditions
      </Link>
    </small>
  )
}

import React from "react"

export const NoteField = () => {
  return (
    <div className="block w-full text-xs">
      <p className="font-serif font-bold text-red-600" style={{ fontSize: 15 }}>
        Delete Item
      </p>
      <p className="italic text-gray-600" style={{ fontSize: 11 }}>
        to delete this item, click on the menu button <br /> beside "Save" and
        click "Delete".
      </p>
      <p
        className="pb-10 mt-2 italic font-bold text-red-600"
        style={{ fontSize: 12 }}
      >
        ONCE DELETED, THIS POST <br />
        CANNOT BE RECOVERED.
      </p>
    </div>
  )
}

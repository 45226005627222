import React, { useRef, useState } from "react"
import * as Sentry from "@sentry/browser"
import { sendEmail, EmailType } from "../../../utils/sendEmail"
import { useIsMobile } from "../../hooks/useDimensions"
import { Button } from "../../atoms/Button"
import { useLang } from "../../Header/LanguageToggle/useLang"
import { Languages } from "../../../provider"
import PhoneInput from "react-phone-number-input"
import "react-phone-number-input/style.css"
import { Disclaimer } from "./Disclaimer"
import { Validator } from "./Validator"
import { FormJobPostText, FormSmallText, FormText } from "./text"
import {
  FILE_SIZE_WARNING,
  MAX_FILE_SIZE,
  EMAIL_FAILURE_WARNING,
  defaultJobPost,
} from "./constants"

export interface JobPost {
  title: string
  date: string
  description: string
  location: string
  jobFunction: string
  seniority: string
  skills: string
  hasSubtitle: boolean
  filterType: string
  consultant: string
  position: string
  linkedIn: string
  email: string
}

const HEIGHT = 10

export const Form: React.FC = () => {
  const lang = useLang()
  const isMobile = useIsMobile()

  const emailRef = useRef<HTMLInputElement>(null)
  const fileRef = useRef<HTMLInputElement>(null)
  const talentRef = useRef<HTMLInputElement>(null)
  const hireRef = useRef<HTMLInputElement>(null)
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  // https://gist.github.com/vdelacou/f5080c76f34a89e3ea8954ae03d64d27
  const getFileFromInput = (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onerror = reject
      reader.onload = () => resolve(reader.result)
      reader.readAsDataURL(file)
      // reader.readAsBinaryString(file) // here the file can be read in different way Text, DataUrl, ArrayBuffer
    })
  }

  const [filePath, setFilePath] = useState<string>("")
  const [fileData, setFileData] = useState<string>()
  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setErrors(e => [...e.filter(v => v !== FILE_SIZE_WARNING)])
      const files = fileRef.current?.files
      const name = (files && files[0].name) || ""

      setFilePath(name)
      // upload file here
      if (files) {
        const size = files && files[0].size

        if (size > MAX_FILE_SIZE) {
          setErrors(e => [...e, FILE_SIZE_WARNING])
          setFilePath(`FILE TOO LARGE - ${name}`)
        } else {
          // process files
          const result = (await getFileFromInput(files[0])) as string
          setFileData(result)
        }
      }
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)

      if (fileRef && fileRef.current) fileRef.current.value = "" // allow upload of same file if error occurs
    }
  }

  const [errors, setErrors] = useState<string[]>([])
  const validate = () => {
    // validate whether user has picked talent or hire

    const validationErrors = Validator({
      emailRef,
      talentOrHireRefs: { hireRef, talentRef },
      fileUploadData: { filePath, fileRef },
    })

    // no textarea validation
    if (validationErrors.length > 0) {
      setErrors(validationErrors)
      return false
    }

    let emailType: string = ""
    const talent = talentRef?.current?.checked
    const hire = hireRef?.current?.checked
    if (talent) {
      emailType = "talent"
    } else if (hire) {
      emailType = "hire"
    }

    const emailAddress = emailRef?.current?.value || ""

    return {
      emailType,
      emailAddress,
      uploadFile: filePath,
    }
  }

  const [submitted, setSubmitted] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const onSubmit = async (e: React.FormEvent) => {
    setErrors([])
    setSubmitting(false)
    e.preventDefault()

    const result = validate()
    const messageBody = textareaRef?.current?.value
    try {
      if (!result) {
        // there are some errors. user should be able to see these errors on the screen.
      } else {
        setSubmitting(true)
        await sendEmail(
          {
            ...result,
            messageBody: messageBody,
            attachment: fileData,
          },
          EmailType.default
        )
        setSubmitting(false)

        setSubmitted(true)
      }
    } catch (e) {
      console.error(e)
      Sentry.captureMessage(
        `EMAIL ERROR: tried and failed to send data: ${JSON.stringify(result)}`
      )
      Sentry.captureException(e)

      setSubmitting(false)
      setSubmitted(false)
      setErrors(e => [...e, EMAIL_FAILURE_WARNING])
    }
  }

  const text = FormText[lang]

  if (submitted) {
    return (
      <div className="flex items-center justify-center mt-10 ">
        <h2 className="leading-tight tracking-normal text-gray-100">
          {text.thankYou}
        </h2>
      </div>
    )
  }

  const mobile = (
    <>
      <div className="flex flex-col justify-between w-full">
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
          <label className="pl-10 text-base text-gray-100 radio-container">
            {text.radio.talent}
            <input
              type="radio"
              id="talent"
              name="email-type"
              value="talent"
              ref={talentRef}
            />
            <span className="border-2 checkmark bg-vp-blue border-vp-darkblue"></span>
          </label>

          <label className="pl-10 text-base text-gray-100 radio-container">
            {text.radio.hire}
            <input
              type="radio"
              id="hire"
              name="email-type"
              value="hire"
              ref={hireRef}
            />
            <span className="border-2 checkmark bg-vp-blue border-vp-darkblue"></span>
          </label>
        </div>

        <input
          className={`h-${HEIGHT} w-full px-4 mt-6 bg-transparent placeholder-gray-100 text-gray-100 border-2 border-vp-darkblue`}
          type="email"
          name="Email Address"
          placeholder={text.email}
          ref={emailRef}
        />

        <div className="flex w-full mt-6">
          <textarea
            placeholder={text.messageBody}
            name="Description"
            className="w-full p-4 text-gray-100 placeholder-gray-100 bg-transparent border-2 border-vp-darkblue"
            style={{ height: 250 }}
            ref={textareaRef}
          />
        </div>

        <div className={`h-${HEIGHT} relative w-full mt-6`}>
          <input
            className={`absolute h-${HEIGHT} opacity-0 z-10 w-full cursor-pointer`}
            type="file"
            name="Uploaded File"
            accept=".doc,.docx,.pdf,.xls,.xlsx"
            ref={fileRef}
            onChange={handleChange}
          />
          <input
            key="filepath-preview-only"
            className={`z-0 h-${HEIGHT} px-4 cursor-pointer bg-transparent placeholder-gray-100 text-gray-100 border-2 border-vp-darkblue w-full`}
            type="text"
            placeholder={text.upload}
            value={filePath}
            readOnly
          />
        </div>
      </div>
    </>
  )

  const desktop = (
    <>
      <div className="flex items-center justify-between w-full">
        <label
          className={`pl-10 text-base text-gray-100 radio-container ${lang ===
            Languages.JP && "smaller tracking-widest"}`}
        >
          {text.radio.talent}
          <input
            type="radio"
            id="talent"
            name="email-type"
            value="talent"
            ref={talentRef}
          />
          <span className="border-2 checkmark bg-vp-blue border-vp-darkblue"></span>
        </label>

        <label
          className={`pl-10 text-base text-gray-100 radio-container ${lang ===
            Languages.JP && "smaller tracking-widest"}`}
        >
          {text.radio.hire}
          <input
            type="radio"
            id="hire"
            name="email-type"
            value="hire"
            ref={hireRef}
          />
          <span className="border-2 checkmark bg-vp-blue border-vp-darkblue"></span>
        </label>

        <input
          className={`h-${HEIGHT} ${lang === Languages.JP &&
            "smaller tracking-widest"} w-2/6 px-4 bg-transparent placeholder-gray-100 text-gray-100 border-2 border-vp-darkblue`}
          style={{ maxWidth: 300 }}
          type="email"
          name="Email Address"
          placeholder={text.email}
          ref={emailRef}
        />

        <div className={`h-${HEIGHT} relative w-2/6`} style={{ maxWidth: 300 }}>
          <input
            className={`absolute h-${HEIGHT} opacity-0 z-10 w-full cursor-pointer`}
            type="file"
            name="Uploaded File"
            accept=".doc,.docx,.pdf,.xls,.xlsx"
            ref={fileRef}
            onChange={handleChange}
          />
          <input
            key="filepath-preview-only"
            className={`z-0 h-${HEIGHT} ${lang === Languages.JP &&
              "smaller tracking-widest"} px-4 cursor-pointer bg-transparent placeholder-gray-100 text-gray-100 border-2 border-vp-darkblue w-full`}
            type="text"
            placeholder={text.upload}
            value={filePath}
            readOnly
          />
        </div>
      </div>

      <div className="flex w-full mt-8">
        <textarea
          placeholder={text.messageBody}
          name="Description"
          className={`${lang === Languages.JP &&
            "smaller tracking-widest"} w-full p-4 text-gray-100 placeholder-gray-100 bg-transparent border-2 border-vp-darkblue`}
          style={{ height: 100 }}
          ref={textareaRef}
        />
      </div>
    </>
  )

  return (
    <div className="flex flex-col">
      <form
        className="flex flex-col font-sans sm:flex-row sm:items-end"
        onSubmit={onSubmit}
      >
        <div className="flex flex-col w-full mr-4">
          {isMobile ? mobile : desktop}
        </div>

        <Button submitting={submitting} />
      </form>
      <div className="w-full mt-6">
        <ul>
          {errors.map((e, i) => (
            <li className="text-lg text-orange-300" key={i}>
              {e}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export const FormSmall: React.FC<{
  jobPost?: JobPost
  url?: string
  type: EmailType
}> = ({ jobPost, url = "unspecified", type }) => {
  const lang = useLang()
  const jobPostData = { ...defaultJobPost, ...jobPost }
  const emailRef = useRef<HTMLInputElement>(null)
  const nameRef = useRef<HTMLInputElement>(null)

  const [errors, setErrors] = useState<string[]>([])
  const validate = () => {
    // reset errors
    setErrors([])

    const validationErrors = Validator({ nameRef, emailRef })
    if (validationErrors.length > 0) {
      setErrors(validationErrors)
      return false
    }

    const emailAddress = emailRef.current!.value
    const name = nameRef.current!.value

    return {
      emailAddress,
      name,
    }
  }

  const [submitted, setSubmitted] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const onSubmit = async (e: React.FormEvent) => {
    setErrors([])
    setSubmitting(false)
    e.preventDefault()

    const result = validate()
    try {
      if (!result) {
        // there are some errors. user should be able to see these errors on the screen.
      } else {
        setSubmitting(true)
        await sendEmail(
          {
            ...result,
            messageBody: `${result.name} - ${result.emailAddress} is interested in the job ${jobPostData.title} posted at ${url}`,
            jobPostData: { ...jobPostData, url: url },
          },
          type
        )
        setSubmitting(false)

        setSubmitted(true)
      }
    } catch (e) {
      console.error(e)
      Sentry.captureMessage(
        `EMAIL ERROR: tried and failed to send data: ${JSON.stringify(result)}`
      )
      Sentry.captureException(e)

      setSubmitting(false)
      setSubmitted(false)
      setErrors(e => [...e, EMAIL_FAILURE_WARNING])
    }
  }

  const text = FormSmallText[lang]

  if (submitted) {
    return (
      <div className="flex mb-4">
        <h2 className="text-2xl leading-snug tracking-normal text-gray-100">
          {text.thankYou}
        </h2>
      </div>
    )
  }

  return (
    <div className="flex flex-col w-full">
      <form className="flex flex-col font-sans" onSubmit={onSubmit}>
        <input
          className={`h-${HEIGHT} px-4 bg-transparent placeholder-gray-100 text-gray-100 border-2 border-vp-darkblue ${lang ===
            Languages.JP && "smaller tracking-widest"}`}
          type="text"
          name="Name"
          placeholder={text.name}
          ref={nameRef}
        />

        <input
          className={`h-${HEIGHT} mt-4 px-4 bg-transparent placeholder-gray-100 text-gray-100 border-2 border-vp-darkblue ${lang ===
            Languages.JP && "smaller tracking-widest"}`}
          type="email"
          name="Email Address"
          placeholder={text.email}
          ref={emailRef}
        />
        <Button submitting={submitting} />
      </form>
      <div className="w-full mt-2">
        <ul className="list-inside">
          {errors.map((e, i) => (
            <li className="text-sm text-orange-300" key={i}>
              {e}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export const FormJobPost: React.FC<{
  jobPost?: JobPost
  url?: string
  type: EmailType
}> = ({ jobPost, url = "unspecified", type }) => {
  const lang = useLang()
  const jobPostData = { ...defaultJobPost, ...jobPost }

  const nameRef = useRef<HTMLInputElement>(null)
  const emailRef = useRef<HTMLInputElement>(null)
  const fileRef = useRef<HTMLInputElement>(null)
  const [phoneNum, setPhoneNum] = useState("")

  // https://gist.github.com/vdelacou/f5080c76f34a89e3ea8954ae03d64d27
  const getFileFromInput = (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onerror = reject
      reader.onload = () => resolve(reader.result)
      reader.readAsDataURL(file)
      // reader.readAsBinaryString(file) // here the file can be read in different way Text, DataUrl, ArrayBuffer
    })
  }

  const [filePath, setFilePath] = useState<string>("")
  const [fileData, setFileData] = useState<string>("")
  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setErrors(e => [...e.filter(v => v !== FILE_SIZE_WARNING)])
      const files = fileRef.current?.files
      const name = (files && files[0].name) || ""

      setFilePath(name)
      // upload file here
      if (files) {
        const size = files && files[0].size

        if (size > MAX_FILE_SIZE) {
          setErrors(e => [...e, FILE_SIZE_WARNING])
          setFilePath(`FILE TOO LARGE - ${name}`)
        } else {
          // process files
          const result = (await getFileFromInput(files[0])) as string
          setFileData(result)
        }
      }
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)

      if (fileRef && fileRef.current) fileRef.current.value = "" // allow upload of same file if error occurs
    }
  }

  const [errors, setErrors] = useState<string[]>([])
  const validate = () => {
    // reset errors
    setErrors([])

    const validationErrors = Validator({
      nameRef,
      emailRef,
      phoneNumber: phoneNum,
      fileUploadData: { fileRef, filePath },
    })

    if (validationErrors.length > 0) {
      setErrors(validationErrors)
      return false
    }

    const emailAddress = emailRef.current!.value
    const name = nameRef.current!.value

    return {
      name,
      phoneNum,
      emailAddress,
      uploadFile: filePath,
    }
  }

  const [submitted, setSubmitted] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const onSubmit = async (e: React.FormEvent) => {
    setErrors([])
    setSubmitting(false)
    e.preventDefault()

    const result = validate()
    try {
      if (!result) {
        // there are some errors. user should be able to see these errors on the screen.
      } else {
        setSubmitting(true)
        await sendEmail(
          {
            ...result,
            attachment: fileData,
            jobPostData: { ...jobPostData, url: url },
          },
          type
        )
        setSubmitting(false)

        setSubmitted(true)
      }
    } catch (e) {
      console.error(e)
      Sentry.captureMessage(
        `EMAIL ERROR: tried and failed to send data: ${JSON.stringify(result)}`
      )
      Sentry.captureException(e)

      setSubmitting(false)
      setSubmitted(false)
      setErrors(e => [...e, EMAIL_FAILURE_WARNING])
    }
  }

  const text = FormJobPostText[lang]

  if (submitted) {
    return (
      <div className="flex items-center justify-center mt-10 ">
        <h2 className="leading-tight tracking-normal text-gray-100">
          {text.thankYou}
        </h2>
      </div>
    )
  }

  const form = (
    <>
      <div className="flex flex-col justify-between w-full">
        <input
          className={`h-${HEIGHT} w-full px-4 mt-2 bg-transparent placeholder-gray-100 text-gray-100 border-2 border-vp-darkblue`}
          type="text"
          name="Name"
          placeholder={text.name}
          ref={nameRef}
        />
        <input
          className={`h-${HEIGHT} w-full px-4 mt-2 bg-transparent placeholder-gray-100 text-gray-100 border-2 border-vp-darkblue`}
          type="email"
          name="Email Address"
          placeholder={text.email}
          ref={emailRef}
        />
        <div className="w-full px-4 mt-2 text-gray-100 placeholder-gray-100 bg-transparent border-2 border-vp-darkblue">
          <PhoneInput
            className={`h-${HEIGHT}` + ""}
            placeholder={text.phone}
            value={phoneNum}
            onChange={setPhoneNum}
          />
        </div>

        <div className={`h-${HEIGHT} relative w-full mt-2`}>
          <input
            className={`absolute h-${HEIGHT} opacity-0 z-10 w-full cursor-pointer`}
            type="file"
            name="Uploaded File"
            accept=".doc,.docx,.pdf,.xls,.xlsx"
            ref={fileRef}
            onChange={handleChange}
          />
          <input
            key="filepath-preview-only"
            className={`z-0 h-${HEIGHT} px-4 cursor-pointer bg-transparent placeholder-gray-100 text-gray-100 border-2 border-vp-darkblue w-full`}
            type="text"
            placeholder={text.upload}
            value={filePath}
            readOnly
          />
        </div>
      </div>
    </>
  )

  return (
    <div className="flex flex-col">
      <form className="flex flex-col font-sans" onSubmit={onSubmit}>
        <div className="flex flex-col w-full mr-4">{form}</div>

        <Disclaimer />
        <Button submitting={submitting} />
      </form>
      <div className="w-full mt-4 ml-4">
        <ul>
          {errors.map((e, i) => (
            <li className="text-lg text-orange-300" key={i}>
              {e}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

import { config } from "../../config"
import * as Sentry from "@sentry/browser"

export enum EmailType {
  default = "default",
  career = "career",
  jobPost = "jobPost",
}

export const sendEmail = async (data = {}, type = EmailType.default) => {
  async function postData(url = "", data = {}, type: EmailType) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *client
      body: JSON.stringify({ ...data, type: type }), // body data type must match "Content-Type" header
    }).catch(e => {
      console.error(e)
      Sentry.captureException(e)
      throw new Error(e)
    })

    return await response.json() // parses JSON response into native JavaScript objects
  }

  const isDev = process.env.NODE_ENV === "development"
  const url = isDev ? config.dev.email.url : config.prod.email.url

  const response = await postData(url, data, type)

  return {
    status: 200,
    message: "Email Sent!",
    debug: response,
  }
}

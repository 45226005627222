import React from "react"
import Carousel, { CarouselProps, ResponsiveType } from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { white, vpBlue } from "../../../assets/colors"
import CarouselDot from "../CarouselDot"

const RESPONSIVE_DEFAULT: ResponsiveType = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
}

interface Props extends Omit<CarouselProps, "responsive"> {
  responsiveBreakpoints?: ResponsiveType
  invertDotColor?: boolean
  carouselRef?: React.Ref<Carousel>
}

export const CarouselWrapper: React.FC<Props> = ({
  children,
  responsiveBreakpoints = RESPONSIVE_DEFAULT,
  invertDotColor,
  carouselRef,
  ...rest
}) => {
  return (
    <div className="relative pb-10">
      <Carousel
        ref={carouselRef}
        responsive={responsiveBreakpoints}
        ssr
        showDots
        arrows={false}
        renderDotsOutside
        customDot={<CarouselDot inverted={invertDotColor || false} />}
        // dotListClass={invertDotColor ? "inverted-dot" : ""}
        {...rest}
      >
        {children}
      </Carousel>
      {/* <style
        dangerouslySetInnerHTML={{
          __html: `
        .react-multi-carousel-dot button {
          border-width: 1px;
          border-color: #555;
          width: 15px;
          height: 15px;
          margin-right: 12px;
        }
        .react-multi-carousel-dot--active button {
          background: ${white};
        }
        .inverted-dot .react-multi-carousel-dot--active button {
          background: ${vpBlue}
        }
      `,
        }}
      /> */}
    </div>
  )
}

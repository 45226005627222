import React from "react"

const Dot: React.FC<{
  inverted: boolean
  active?: boolean
  onClick?: () => {}
}> = ({ inverted, active, onClick }) => {
  let dotColor
  if (inverted) {
    dotColor = active ? "bg-vp-blue" : "transparent"
  } else {
    dotColor = active ? "bg-white" : "transparent"
  }
  const trigger = () => onClick && onClick()
  return (
    <button
      onClick={trigger}
      className="mr-4 border rounded-full"
      style={{ padding: 2, borderColor: "#44444488", outline: "none" }}
    >
      <div
        className={`${dotColor} rounded-full`}
        style={{ height: 12, width: 12 }}
      ></div>
    </button>
  )
}

export default Dot

import React from "react"

/*
<defs><style>.cls-1{fill:#a2a9ad;}
.cls-2,.cls-4{fill:none;stroke:#fff;stroke-width:1.05px;}
.cls-2{stroke-linejoin:bevel;}
.cls-3{fill:#0055b8;}
.cls-4{stroke-miterlimit:10;}</style></defs>

*/

export const EmailIcon: React.FC<{ to: string }> = ({ to = "#" }) => {
  return (
    <a href={to} target="_blank" rel="noreferrer nopnener">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={51}
        width={51}
        viewBox="0 0 53.04 51.97"
      >
        <g id="Layer_2" data-name="Layer 2">
          <g id="LAYOUT">
            <rect className="cls-1" width="53.04" height="51.97" rx="11.67" />
            <polyline
              className="cls-2"
              points="9.55 13.34 26.55 28.84 44.55 13.34 9.55 13.34 9.55 38.34 34.05 38.34"
            />
            <circle className="cls-3" cx="40.31" cy="38.19" r="3.16" />
            <polyline
              className="cls-4"
              points="32.05 35.84 34.59 38.38 32.09 40.88"
            />
          </g>
        </g>
      </svg>
      <div
        dangerouslySetInnerHTML={{
          __html: `
        <defs><style>.cls-1{fill:#a2a9ad;}
        .cls-2,.cls-4{fill:none;stroke:#fff;stroke-width:1.05px;}
        .cls-2{stroke-linejoin:bevel;}
        .cls-3{fill:#0055b8;}
        .cls-4{stroke-miterlimit:10;}</style></defs>
      `,
        }}
      />
    </a>
  )
}

import React from "react"

export enum BG {
  white = "bg-white",
  gray = "bg-vp-lightgray",
  blue = "bg-vp-blue",
}

interface Props {
  bg?: BG
  fullWidth?: boolean
  fullHeight?: boolean
  classes?: string
  id?: string
}

export const Section: React.FC<Props> = ({
  children,
  bg = BG.white,
  fullWidth = false,
  fullHeight = false,
  classes,
  id,
}) => (
  <div
    id={id}
    className={`${classes} flex flex-col ${
      fullWidth ? "" : "px-6 xs:px-10 sm:px-16"
    } ${fullHeight ? "" : "pt-16 sm:pt-24"} ${bg}`}
  >
    {children}
  </div>
)

import React from "react"

interface Props {
  content?: string
}

export const Title: React.FC<Props> = ({ content, children }) => (
  <h1
    className="font-bold tracking-normal text-vp-blue"
    style={{ lineHeight: 0.9 }}
  >
    {content}
    {children}
  </h1>
)

export const TitleH4: React.FC<{ title: JSX.Element | string }> = ({
  title,
}) => {
  return (
    <h4 className="m-0 font-sans font-bold leading-tight tracking-wider uppercase text-vp-blue">
      {title}
    </h4>
  )
}

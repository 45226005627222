import React, { ReactChildren, useState } from "react"
import { Link } from "gatsby"
import Img, { FluidObject } from "gatsby-image"

import { motion } from "framer-motion"
import { PlusIcon } from "../PlusIcon"
import { FadeIn } from "../../animations/FadeIn"
import { clampedRandom, truncate } from "../../../utils"
import { TitleH4 } from "../Title"
import { LinkedInIcon } from "../LinkedInIcon"
import { EmailIcon } from "../EmailIcon"
import { useDimensions, useIsMobile } from "../../hooks/useDimensions"
import { LinkButton } from "../Button"

export const SPACING = "4" // tailwind -2 e.g. mx-2, not 2px
const DUMMY_TEXT =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore."

interface ListCardProps {
  title: string
  content?: string | JSX.Element
  contentList?: string[]
  link?: {
    label: string
    url: string
  }
  fadeIn?: boolean
  titleColor?: string
  listColor?: string
  divisions?: 2 | 3
}

export const SimpleColumnContainer: React.FC = ({ children }) => {
  return (
    <div className={`flex -mx-${SPACING} justify-between flex-col sm:flex-row`}>
      {children}
    </div>
  )
}

export const TripleColumnContainer: React.FC = ({ children }) => {
  const { width } = useDimensions()
  const isMobile = useIsMobile()

  const breakpoint = (width: number) => {
    if (width > 1024) return "lg"
    if (width > 768) return "md"
    return "sm"
  }

  const gridColumnMapping = {
    sm: "1fr 1fr 1fr",
    md: "1fr 1fr 1fr",
    lg: "1fr 1fr 1fr",
  }

  const displayType = isMobile ? "flex" : "grid"
  const rowGap = isMobile ? 0 : 20

  return (
    <div
      style={{
        display: displayType,
        gridTemplateColumns: gridColumnMapping[breakpoint(width)],
        rowGap: rowGap,
      }}
      className="flex-col"
    >
      {children}
    </div>
  )
}

export const GridCardContainer: React.FC = ({ children }) => (
  <div
    style={{
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gridGap: `${parseInt(SPACING) / 2.5}rem`,
    }}
  >
    {children}
  </div>
)

export const ListCard: React.FC<ListCardProps> = ({
  title = "title",
  contentList = ["one", "two"],
  content,
  fadeIn,
  titleColor = "vp-blue",
  listColor = "gray-700",
  link,
}) => {
  const [hovered, setHovered] = useState(false)
  const [open, setOpen] = useState(false)
  const isMobile = useIsMobile()

  const innerContent = content ? (
    content
  ) : (
    <>
      <ul className="mt-2 ml-4 sm:text-sm">
        {contentList.map(text => (
          <li className={`text-${listColor} leading-tight`} key={text}>
            {text}
          </li>
        ))}
      </ul>
      {link ? <LinkButton url={link.url} label={link.label} /> : null}
    </>
  )

  const variants = {
    open: {
      height: "fit-content",
      opacity: 1,
    },
    closed: {
      height: 0,
      opacity: 0,
      transition: {
        duration: 0.1,
      },
    },
  }

  const handleClick = () => setOpen(p => !p)
  const onHoverStart = () => setHovered(true)
  const onHoverEnd = () => setHovered(false)

  const result = (
    <motion.div
      className="flex flex-col p-6 mb-4 border-2 border-gray-400 rounded-lg group sm:border-0 sm:mb-0 sm:p-0 hover:shadow-lg sm:hover:shadow-none"
      style={{ transition: "box-shadow 300ms ease" }}
      onHoverStart={onHoverStart}
      onHoverEnd={onHoverEnd}
    >
      <div
        className={`flex justify-between items-center tracking-wider uppercase text-${titleColor} font-bold font-sans m-0`}
      >
        <span className="sm:text-sm">{title}</span>{" "}
        {isMobile && (
          <div onClick={handleClick} className="cursor-pointer">
            <PlusIcon width={30} hovered={hovered} minus={open} invertColor />
          </div>
        )}
      </div>
      {isMobile ? (
        <motion.div
          variants={variants}
          animate={open ? "open" : "closed"}
          className="pointer-events-none"
        >
          {innerContent}
        </motion.div>
      ) : (
        innerContent
      )}
    </motion.div>
  )

  return fadeIn ? (
    <FadeIn delay={clampedRandom()} classes={`mr-${SPACING}`}>
      {result}
    </FadeIn>
  ) : (
    <div className={`mr-${SPACING}`}>{result}</div>
  )
}

export const ListCardWithoutBoxOnMobile: React.FC<ListCardProps> = ({
  title = "title",
  contentList = ["one", "two"],
  content,
  fadeIn,
  titleColor = "vp-blue",
  listColor = "gray-600",
  divisions = 3,
}) => {
  const innerContent = content ? (
    content
  ) : (
    <ul className="mt-2 ml-4">
      {contentList.map(text => (
        <li className={`text-${listColor} leading-tight`} key={text}>
          {text}
        </li>
      ))}
    </ul>
  )

  const result = (
    <div className="flex flex-col p-4 mb-4 group sm:mb-0 sm:p-0">
      <div
        className={`flex justify-between items-center tracking-wider uppercase text-${titleColor} font-bold font-sans m-0`}
      >
        <span>{title}</span>{" "}
      </div>
      {innerContent}
    </div>
  )

  return fadeIn ? (
    <FadeIn
      delay={clampedRandom()}
      classes={`sm:w-1/${divisions} sm:mx-${SPACING}`}
    >
      {result}
    </FadeIn>
  ) : (
    <div className={`sm:w-1/${divisions} sm:mx-${SPACING}`}>{result}</div>
  )
}

interface ServicesCardProps {
  title: string
  subtitle: string
  description: string
  contentList: string[]
}

export const ServicesCard: React.FC<ServicesCardProps> = ({
  title = "Title",
  subtitle = "subtitle",
  description = "description",
  contentList = ["content1", "content2"],
}) => (
  <div className={`p-8 border border-gray-400 bg-white w-1/2 mx-${SPACING}`}>
    <h3 className="m-0 font-sans font-bold uppercase h3-sans text-vp-blue">
      {title}
    </h3>
    <p className="italic text-gray-600">{subtitle}</p>
    <p className="mt-6">{description}</p>
    <ul className="mt-6">
      {contentList.map(text => (
        <li className="font-hairline tracking-wide text-gray-900" key={text}>
          {text}
        </li>
      ))}
    </ul>
  </div>
)

interface RecentPositions {
  title: string
  titleClass: string
  type: string
  location: string
  description: string
  slug: string
  date?: string
  fadeIn?: boolean
  mobile?: boolean
}

export const RecentPositionsCard: React.FC<RecentPositions> = ({
  title,
  titleClass,
  type,
  location,
  description,
  slug,
  date,
  fadeIn,
  mobile,
}) => {
  const [hovered, setHovered] = useState(false)
  const onHoverStart = () => setHovered(true)
  const onHoverEnd = () => setHovered(false)
  const result = (
    <motion.div
      onHoverStart={onHoverStart}
      onHoverEnd={onHoverEnd}
      style={{
        transition: "box-shadow 300ms ease",
        minHeight: mobile ? 0 : 280,
      }}
      className={
        "group py-3 px-4 sm:p-6 border-2 rounded-lg border-vp-darkblue w-full h-full hover:shadow-lg overflow-visible"
      }
    >
      <Link
        to={slug}
        className="flex items-center justify-between h-full sm:flex-col sm:items-start"
      >
        <div className="w-4/5 text-gray-100 sm:w-full">
          <h4
            className={`m-0 font-serif font-bold tracking-normal ${titleClass}`}
            style={{
              fontSize: mobile ? 18 : 25,
              lineHeight: mobile ? 1.2 : "",
            }}
          >
            {title}
          </h4>
          <div className="flex mt-1 sm:mb-2">
            <p
              className={`text-base text-gray-300 sm:text-white sm:mt-1 leading-tighter ${!mobile &&
                "border-2 border-vp-darkblue px-2 py-1 rounded-lg -ml-1"}`}
              style={{ fontSize: 13 }}
            >
              {type}
            </p>
          </div>
          {!mobile && (
            <>
              <p
                className="text-base italic pointer-events-none leading-tighter"
                style={{ fontSize: 13 }}
              >
                {location}
              </p>
              <p
                className="mt-2 text-base leading-tight tracking-tight"
                style={{ fontSize: 13 }}
              >
                {truncate(description, 250)}
              </p>
              {date && (
                <p className="mt-4 text-xs">{new Date(date).toDateString()}</p>
              )}
            </>
          )}
        </div>

        <div className="w-6 h-6 sm:mt-6">
          <PlusIcon hovered={hovered} />
        </div>
      </Link>
    </motion.div>
  )

  return fadeIn ? (
    <FadeIn delay={clampedRandom()} threshold={0.1} classes="h-full w-full">
      {result}
    </FadeIn>
  ) : (
    result
  )
}

interface Announcements {
  slug: string
  titleClass: string
  fadeIn?: boolean
  mobile?: boolean
  title: string
  description: string
}

export const AnnouncementsCard: React.FC<Announcements> = ({
  titleClass,
  slug,
  fadeIn,
  mobile,
  title,
  description,
}) => {
  const [hovered, setHovered] = useState(false)
  const onHoverStart = () => setHovered(true)
  const onHoverEnd = () => setHovered(false)
  const result = (
    <motion.div
      onHoverStart={onHoverStart}
      onHoverEnd={onHoverEnd}
      style={{
        transition: "box-shadow 300ms ease",
        minHeight: mobile ? 0 : 280,
      }}
      className={`
        group py-3 px-4 sm:p-6 border-2 rounded-lg 
        border-vp-darkblue bg-gray-100 hover:bg-gray-50 
        w-full h-full hover:shadow-lg overflow-visible
        ${slug ? "" : "pointer-events-none"}
        `}
    >
      <Link
        to={slug}
        className={`flex items-center justify-between h-full sm:flex-col sm:items-start`}
      >
        <div className="w-4/5 text-vp-darkblue sm:w-full">
          <h4
            className={`m-0 font-serif font-bold tracking-normal ${titleClass}`}
            style={{
              fontSize: mobile ? 18 : 25,
              lineHeight: mobile ? 1.2 : "",
            }}
          >
            {title || "title"}
          </h4>
          <p
            className="mt-2 text-base leading-tight tracking-tight"
            style={{ fontSize: 13 }}
          >
            {description || "description"}
          </p>
        </div>

        <div className="w-6 h-6 sm:mt-6">
          <PlusIcon hovered={hovered} invertColor />
        </div>
      </Link>
    </motion.div>
  )

  return fadeIn ? (
    <FadeIn delay={clampedRandom()} threshold={0.1} classes="h-full w-full">
      {result}
    </FadeIn>
  ) : (
    result
  )
}

interface HowItWorksCardProps {
  title: string | JSX.Element
  number: number
  description: string | JSX.Element
  spacing?: number
  titleClasses?: string
  titleSizeClasses?: string
  titleStyle?: any
}

export const HowItWorksCard: React.FC<HowItWorksCardProps> = ({
  title = "Title",
  number = 0,
  description = DUMMY_TEXT,
  spacing = SPACING,
  titleClasses,
  titleSizeClasses = "text-2xl",
  titleStyle,
}) => (
  <div
    className={`p-4 sm:px-6 sm:pt-6 sm:pb-10 bg-white h-full border-2 border-gray-400 rounded-lg mx-${spacing} flex flex-col`}
  >
    <div className={`flex ${titleClasses}`} style={titleStyle}>
      <h3
        className={`font-serif ${titleSizeClasses} text-gray-900 font-bold m-0 leading-none mr-2`}
      >
        {number.toString()}.
      </h3>
      <h3
        className={`font-serif ${titleSizeClasses} text-gray-900 font-bold m-0 leading-none`}
      >
        {title}
      </h3>
    </div>

    <p className="mt-2 leading-tight tracking-tighter text-gray-900">
      {description}
    </p>
  </div>
)

interface WhyPeopleProps {
  title: string
  description: string
}

export const WhyPeopleCard: React.FC<WhyPeopleProps> = ({
  title = "title",
  description = DUMMY_TEXT,
}) => (
  <div className={`p-8 border border-gray-400 bg-white w-full`}>
    <p className="m-0 font-bold">{title}</p>

    <p className="font-hairline text-gray-900">{description}</p>
  </div>
)

export interface RightMoveProps {
  fromCompany: string
  toCompany: string
  placing?: string
  role?: string
  age: number
  description: string
  to?: string
  compensationIncrease?: number
}

export const RightMoveCard: React.FC<RightMoveProps> = ({
  placing,
  fromCompany = "Japanese Utility Provider",
  toCompany = "Management Consulting",
  role,
  age = 40,
  compensationIncrease,
  description = "Identified a candidate that spent his entire prior career with a major Japanese utility and introduced him to astrategic consulting firm. The candidate recently became a Partner in his new firm.",
}) => (
  <div
    className={`h-full p-6 xs:py-6 xs:px-8 sm:px-10 shadow-lg border-2 border-gray-100 rounded-lg bg-white w-full text-center`}
  >
    <p className="font-sans text-sm tracking-widest">FROM</p>
    <p className="mb-1 font-serif text-lg font-bold text-gray-700 sm:text-xl">
      {fromCompany}
    </p>

    <p className="font-sans text-sm tracking-widest">TO</p>
    <p className="mt-2 mb-2 font-serif text-3xl font-bold leading-none text-vp-blue">
      {toCompany}
    </p>

    <p className="text-xs italic font-bold leading-tight text-vp-blue">
      {placing && `Placed: ${placing} | `}
      {role && `Current role: ${role} | `}
      {compensationIncrease &&
        `Compensation increase: ${compensationIncrease}% | `}
      Age: {age.toString()}
    </p>

    <p className="mt-4 text-xs leading-tight sm:text-sm">{description}</p>
  </div>
)

// TODO: make these enums
// make location a certain format
// add validation
interface JobFunction {
  title: string
}
interface Industry {
  title: string
}
interface LinkedInCardProps {
  location: any
  jobFunction?: JobFunction[]
  seniority: any
  industry?: Industry[]
  additionalClassName?: string
}

export const LinkedInCard: React.FC<LinkedInCardProps> = ({
  location,
  jobFunction,
  seniority,
  industry,
  additionalClassName,
}) => (
  <div className={`e ${additionalClassName}`}>
    <p
      id="linkedin-location"
      className="px-4 py-2 mb-4 tracking-wide text-gray-100 bg-vp-gray"
    >
      Location : {location}
    </p>
    <p
      id="linkedin-seniority"
      className="px-4 py-2 mb-4 tracking-wide text-gray-100 bg-vp-gray"
    >
      Seniority : {seniority}
    </p>
    <div id="linkedin-function" className="flex px-4 py-2 mb-4 bg-vp-gray ">
      <p className="tracking-wide text-gray-100 whitespace-no-wrap">
        Function :{" "}
      </p>
      <ul className="ml-1 list-none" style={{ marginTop: "0.2rem" }}>
        {jobFunction?.map((j, i) => {
          return (
            <li
              key={i}
              className="mb-2 leading-tight tracking-wide text-gray-100"
            >
              {j.title}
            </li>
          )
        })}
      </ul>
    </div>

    <div id="linkedin-industry" className="flex px-4 py-2 mb-4 bg-vp-gray ">
      <p className="tracking-wide text-gray-100 whitespace-no-wrap">
        Industry :{" "}
      </p>
      <ul className="ml-1 list-none" style={{ marginTop: "0.2rem" }}>
        {industry?.map((j, i) => {
          return (
            <li
              key={i}
              className="mb-2 leading-tight tracking-wide text-gray-100"
            >
              {j.title}
            </li>
          )
        })}
      </ul>
    </div>
  </div>
)

interface FAQCardProps {
  question: string | JSX.Element
  answer: string | JSX.Element
}

export const FAQCard: React.FC<FAQCardProps> = ({
  question = "I am not aggressively thinking to move, but is it okay to request a counselling session?",
  answer = "Sure you can! Please do not hesitate to register or simply contact us for any questions, always happy to have an information exchange session. We are in the business of creating long term relationships.",
}) => {
  return (
    <div className="flex flex-col">
      <p className="font-serif text-2xl font-bold leading-tight tracking-tight text-gray-900">
        {question}
      </p>
      <p className="mt-4 text-base leading-tight text-gray-900">{answer}</p>
    </div>
  )
}

interface TheTeam {
  name: string
  subtitle: string
  fluid: FluidObject
  slug: string
  fadeIn?: boolean
  mobile?: boolean
}

export const TheTeamCard: React.FC<TheTeam> = ({
  name,
  subtitle,
  fluid,
  slug,
  fadeIn,
  mobile,
}) => {
  const [hovered, setHovered] = useState(false)
  const onHoverStart = () => setHovered(true)
  const onHoverEnd = () => setHovered(false)
  const result = (
    <motion.div
      onHoverStart={onHoverStart}
      onHoverEnd={onHoverEnd}
      style={{ transition: "box-shadow 300ms ease" }}
      className={
        "group bg-white p-4 sm:p-6 border-2 rounded-lg border-gray-400 w-full h-full hover:shadow-lg"
      }
    >
      <Link to={slug} className="flex flex-col justify-between h-full">
        <div>
          {/* todo: get from img slug from tina cms */}
          {fluid ? (
            <Img
              className="mb-4 h-72 xs:h-48 sm:h-96 bg-vp-lightgray sm:mb-6"
              style={{
                filter: hovered ? "grayscale(0%)" : "grayscale(100%)",
                transition: "all 500ms ease",
              }}
              fluid={fluid}
            />
          ) : (
            <div className="h-48 mb-4 sm:h-64 bg-vp-lightgray sm:mb-6" />
          )}
          <h4
            className="m-0 font-serif text-xl font-bold leading-none tracking-normal sm:text-3xl text-vp-blue"
            style={{ fontSize: mobile ? "" : 25 }}
          >
            {name}
          </h4>
          <p
            className="text-base italic text-gray-600 sm:mt-1 leading-tighter"
            style={{ fontSize: 13 }}
          >
            {subtitle}
          </p>
        </div>

        <div className="self-end w-8 mt-2 sm:mt-4 sm:self-start">
          <PlusIcon hovered={hovered} invertColor />
        </div>
      </Link>
    </motion.div>
  )

  return fadeIn ? (
    <FadeIn delay={clampedRandom()} threshold={0.1} classes="h-full w-full">
      {result}
    </FadeIn>
  ) : (
    result
  )
}

interface TheTeamDetail {
  name: string
  role: string
  img: FluidObject
  slug: string
  linkedIn: string
  email: string
  fadeIn?: boolean
}

export const TheTeamCardDetail: React.FC<TheTeamDetail> = ({
  name = "Consultant Name",
  role,
  img,
  slug,
  fadeIn,
  email,
  linkedIn,
}) => {
  const result = (
    <motion.div className={"bg-white w-full h-full mt-4"}>
      <TitleH4 title="Can I Help?" />
      {/* todo: get from img slug from tina cms */}
      {img ? (
        <Img className="w-full mt-4 mb-6 bg-vp-lightgray" fluid={img} />
      ) : (
        <div className="mt-4 mb-6 bg-red-600" style={{ height: 300 }} />
      )}
      <h4
        className="m-0 font-serif text-3xl font-bold leading-none tracking-normal text-vp-blue"
        style={{ fontSize: 25 }}
      >
        {name}
      </h4>
      <p
        className="mt-1 text-base italic text-gray-600 leading-tighter"
        style={{ fontSize: 13 }}
      >
        {role}
      </p>
      <div className="flex mt-2">
        <LinkedInIcon to={linkedIn} />
        <div className="ml-2">
          <EmailIcon to={"mailto:" + email} />
        </div>
      </div>
    </motion.div>
  )

  return fadeIn ? (
    <FadeIn delay={clampedRandom()} threshold={0.1} classes="h-full w-full">
      {result}
    </FadeIn>
  ) : (
    result
  )
}

export interface RecommendationsCardProps {
  text: string
  author: string
}

export const RecommendationsCard: React.FC<RecommendationsCardProps> = ({
  text,
  author,
}) => {
  return (
    <div
      className="flex flex-col justify-between w-full h-full p-6 overflow-hidden bg-white border border-gray-100 rounded-lg shadow-md xs:p-8 hover:shadow-lg"
      style={{ minHeight: "16rem" }}
    >
      <p className="text-xs leading-tight xs:text-sm sm:text-base">"{text}"</p>
      <p className="mt-4 italic text-vp-blue">{author}</p>
    </div>
  )
}

export const defaultJobPost = {
  title: "unspecified",
  date: "unspecified",
  description: "unspecified",
  location: "unspecified",
  jobFunction: "unspecified",
  seniority: "unspecified",
  skills: "unspecified",
  hasSubtitle: false,
  filterType: "unspecified",
  consultant: "unspecified",
  position: "unspecified",
  linkedIn: "unspecified",
  email: "unspecified",
}

export const MAX_FILE_SIZE = 5000000 // 5mb

export const RADIO_WARNING = "Please pick either talent or hire."
export const NAME_WARNING = "Please enter your name."
export const EMAIL_WARNING = "Please enter your email address."
export const PHONE_WARNING = "Please enter a valid phone number."
export const UPLOAD_WARNING = "Please Upload your CV of Brief"
export const EMAIL_FAILURE_WARNING =
  "hmm. we seem to be having some issues with the email service. Would you like to try again?"

export const FILE_SIZE_WARNING = `File Upload max size exceeded. Please upload files smaller than ${MAX_FILE_SIZE /
  1000000}MB.`

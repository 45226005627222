import { isPossiblePhoneNumber } from "react-phone-number-input"
import {
  MAX_FILE_SIZE,
  NAME_WARNING,
  EMAIL_WARNING,
  PHONE_WARNING,
  RADIO_WARNING,
  UPLOAD_WARNING,
  FILE_SIZE_WARNING,
} from "./constants"

const validInputLength = (input: string) => input.length > 0

const talentOrHire = (talentInput: boolean, hireInput: boolean) =>
  talentInput === true || hireInput === true

interface Validator {
  nameRef?: React.RefObject<HTMLInputElement>
  emailRef?: React.RefObject<HTMLInputElement>
  phoneNumber?: string
  talentOrHireRefs?: {
    talentRef: React.RefObject<HTMLInputElement>
    hireRef: React.RefObject<HTMLInputElement>
  }
  fileUploadData?: {
    filePath: string
    fileRef: React.RefObject<HTMLInputElement>
  }
}

export const Validator = ({
  nameRef,
  emailRef,
  talentOrHireRefs,
  fileUploadData,
  phoneNumber,
}: Validator) => {
  let errors = []

  if (nameRef) {
    const isValid = validInputLength(nameRef.current?.value || "")
    !isValid && errors.push(NAME_WARNING)
  }

  if (emailRef) {
    const isValid = validInputLength(emailRef.current?.value || "")
    !isValid && errors.push(EMAIL_WARNING)
  }

  if (phoneNumber) {
    const isValid =
      validInputLength(phoneNumber) || !isPossiblePhoneNumber(phoneNumber)
    !isValid && errors.push(PHONE_WARNING)
  }

  if (talentOrHireRefs) {
    const talentRef = talentOrHireRefs.talentRef
    const hireRef = talentOrHireRefs.hireRef
    const talent = talentRef?.current?.checked || false
    const hire = hireRef?.current?.checked || false

    const isValid = talentOrHire(talent, hire)
    !isValid && errors.push(RADIO_WARNING)
  }

  if (fileUploadData) {
    const validFilePath = validInputLength(fileUploadData.filePath)
    !validFilePath && errors.push(UPLOAD_WARNING)

    if (validFilePath) {
      const files = fileUploadData.fileRef.current?.files
      const size = files ? files[0]?.size : 0
      if (size > MAX_FILE_SIZE) {
        errors.push(FILE_SIZE_WARNING)
      }
    }
  }

  return errors
}

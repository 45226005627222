import React from "react"
import { Languages } from "../../../provider"

export const FormText = {
  [Languages.EN]: {
    radio: {
      talent: "I am a talent",
      hire: "I want to hire",
    },
    email: "Email address",
    upload: "Upload CV or Brief",
    thankYou: "Thank you. We will contact you shortly.",
    messageBody: "What's on your mind?",
  },
  [Languages.JP]: {
    radio: {
      talent: "転職をご希望の方​",
      hire: "採用をお考えの方",
    },
    email: "メールアドレス",
    upload: "CV,またはBrief添付",
    thankYou: "ありがとうございました。",
    messageBody: "お問い合わせ内容​",
  },
}

export const FormSmallText = {
  [Languages.EN]: {
    name: "Name",
    email: "Email address",
    thankYou: (
      <p>
        Thank you. <br /> We will contact you shortly.
      </p>
    ),
  },
  [Languages.JP]: {
    name: "名前",
    email: "メールアドレス",
    thankYou: "ありがとうございました。",
  },
}

export const FormJobPostText = {
  [Languages.EN]: {
    name: "Name",
    email: "Email address",
    phone: "Phone number",
    upload: "Upload CV",
    thankYou: (
      <p>
        Thank you. <br /> We will contact you shortly.
      </p>
    ),
  },
  [Languages.JP]: {
    name: "名前",
    email: "メールアドレス",
    phone: "電話番号",
    upload: "CV添付",
    thankYou: "ありがとうございました。",
  },
}

import React from "react"
import { useIsMobile } from "./hooks/useDimensions"
import Img from "gatsby-image"

export const VPImage = ({ fluid }: { fluid: any }) => {
  const isMobile = useIsMobile()

  if (!fluid) {
    return (
      <div
        className="object-cover w-full h-full bg-gray-400"
        style={{ minHeight: 300, maxHeight: 500, width: isMobile ? 250 : 300 }}
      />
    )
  }

  return (
    <div
      className="flex items-start justify-start h-full md:justify-end"
      style={{ minWidth: 300 }}
    >
      <Img
        fluid={fluid}
        className="object-cover"
        style={{
          // maxHeight: isMobile ? 400 : 400,
          // height: 350,
          width: isMobile ? 250 : 300,
        }}
      />
    </div>
  )
}

import React, {
  forwardRef,
  RefForwardingComponent,
  ComponentPropsWithoutRef,
  useState,
  useEffect,
} from "react"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { clampedRandom } from "../../../utils"

interface FadeInProps {
  classes?: string
  style?: any
  threshold?: number
  delay?: number
  duration?: number
  y?: number
  hover?: boolean
}

interface FadeInBaseProps extends FadeInProps {
  inView: boolean
}

const FadeInFactory: RefForwardingComponent<
  HTMLDivElement,
  ComponentPropsWithoutRef<"div"> & FadeInBaseProps
> = (
  {
    children,
    classes,
    style,
    delay = 0,
    y = 50,
    duration = 0.35,
    inView,
    hover,
  },
  ref
) => {
  const variants = {
    hidden: { opacity: 0, y: y },
    visible: { opacity: 1, y: 0 },
  }

  const [isVisible, setIsVisible] = useState(true)
  useEffect(() => {
    setIsVisible(false)
  }, [])
  useEffect(() => {
    inView && setIsVisible(inView)
  }, [inView])

  return (
    <motion.div
      ref={ref}
      variants={variants}
      initial={"visible"}
      animate={isVisible ? "visible" : "hidden"}
      transition={{ delay: delay, duration: duration, ease: "easeOut" }}
      whileHover={{
        scale: hover ? 1.02 : 1,
        transition: { delay: 0, duration: 0.05 },
      }}
      className={classes}
      style={{
        ...style,
      }}
    >
      {children}
    </motion.div>
  )
}

export const FadeInBase = forwardRef(FadeInFactory)

export const FadeIn: React.FC<FadeInProps> = ({ threshold = 1, ...rest }) => {
  const [ref, inView, entry] = useInView({ threshold })
  return <FadeInBase ref={ref} inView={inView} {...rest} />
}

export const FadeInStaggered: React.FC = ({ children }) => {
  const rand = clampedRandom()
  return <FadeIn delay={rand}>{children}</FadeIn>
}

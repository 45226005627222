import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

export const LinkedInIcon: React.FC<{ to: string }> = ({ to = "#" }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "linkedin-icon.png" }) {
        childImageSharp {
          fixed(width: 60, height: 60, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <a href={to} target="_blank" rel="noreferrer noopener">
      <Img
        fixed={data.placeholderImage.childImageSharp.fixed}
        style={{ margin: -4 }}
      />
    </a>
  )
}

import React from "react"
import { Section, BG } from "../atoms/Section"
import { Title, TitleH4 } from "../atoms/Title"
import { useIsMobile } from "../hooks/useDimensions"
import { FadeIn } from "../animations/FadeIn"

interface AwardProps {
  year: number
  sector: string
  title: string
  awardOrganization: string
}

const Award: React.FC<AwardProps> = ({
  year = 2222,
  sector = "Consulting Sector (demo)",
  title = "MVP Award Japan (demo)",
  awardOrganization = "BizReach (demo)",
}) => (
  <div className="py-8 cursor-default">
    <p className="font-bold text-center text-vp-blue">
      {year && year.toString()}
    </p>
    <p className="font-bold text-center text-vp-blue">{sector}</p>
    <p className="font-bold text-center text-vp-blue">{title}</p>
    <p className="text-center">({awardOrganization})</p>
  </div>
)

const DUMMY = {
  year: 1111,
  sector: "Consulting Sector (demo)",
  title: "MVP Award Japan (demo)",
  awardOrganization: "BizReach (demo)",
}
const DUMMY_LIST = [DUMMY, DUMMY, DUMMY, DUMMY, DUMMY, DUMMY, DUMMY, DUMMY]

const Awards: React.FC<{
  bg?: BG
  awardsList: AwardProps[]
  smallTitle?: boolean
}> = ({ bg = BG.white, awardsList = DUMMY_LIST, smallTitle }) => {
  const isMobile = useIsMobile()
  return (
    <Section bg={bg}>
      {smallTitle ? <TitleH4 title="Awards" /> : <Title content="Awards" />}
      <div
        className="mt-2"
        style={{
          display: "grid",
          gridTemplateColumns: isMobile ? "1fr 1fr" : "1fr 1fr 1fr 1fr",
          gridGap: "1.5rem",
        }}
      >
        {awardsList.map((data, i) => {
          return (
            <FadeIn hover key={`awards-${i}`} delay={(i + 1) / 10}>
              <Award
                year={data.year}
                sector={data.sector}
                title={data.title}
                awardOrganization={data.awardOrganization}
              />
            </FadeIn>
          )
        })}
      </div>
      <br />
      <br />
      <br />
    </Section>
  )
}

export default Awards

import React from "react"

const HEIGHT = 10

interface Button {
  submitting?: boolean
  text?: string
}

export enum ButtonType {
  dark = "dark",
}

export const Button: React.FC<Button &
  React.DOMAttributes<HTMLButtonElement>> = ({ submitting, text, onClick }) => {
  return (
    <button
      className={
        `h-${HEIGHT} mt-4 font-sans flex items-center mt-6 py-2 px-4 rounded-lg border-2 border-gray-100 ${
          submitting ? "bg-gray-500" : "bg-vp-blue"
        } hover:bg-vp-darkblue text-white text-sm font-hairline` +
        "transition duration-300 ease-in-out"
      }
      style={{ width: 125 }}
      type="submit"
      disabled={submitting}
      onClick={onClick}
    >
      <span className="mr-2">
        {text || (submitting ? "SENDING..." : "SEND")}
      </span>{" "}
      {/* <Arrow color="white" thickness={1.5} noMargin /> */}
    </button>
  )
}

interface Link {
  label: string
  url: string
  additionalClasses?: string
}

export const LinkButton: React.FC<Link &
  React.DOMAttributes<HTMLLinkElement>> = ({
  url,
  label,
  additionalClasses,
}) => {
  return (
    <a
      href={url}
      target="__blank"
      className={
        `h-${HEIGHT} mt-4 font-sans flex items-center mt-6 py-2 px-4 rounded-lg border-2 border-gray-100 bg-vp-blue hover:bg-vp-darkblue text-white text-sm font-hairline ${additionalClasses}` +
        " transition duration-300 ease-in-out pointer-events-auto"
      }
      style={{ width: "fit-content" }}
    >
      {label}
    </a>
  )
}

import React from "react"
import { Section, BG } from "../atoms/Section"
import { TitleH4 } from "../atoms/Title"
import { CarouselWrapper } from "../atoms/Carousel"
import { RecommendationsCard, RecommendationsCardProps } from "../atoms/Cards"
import { ResponsiveType } from "react-multi-carousel"
import { useDimensions } from "../hooks/useDimensions"

interface Recommendations {
  recommendationsList: RecommendationsCardProps[]
}

export const Recommendations: React.FC<Recommendations> = ({
  recommendationsList,
}) => {
  const responsive: ResponsiveType = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 0,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  }

  const dimensions = useDimensions()

  return (
    <Section bg={BG.gray} fullWidth fullHeight>
      <div className="px-6 pt-10 xs:px-10 sm:px-16 sm:pt-24">
        <TitleH4 title="Recommendations" />
      </div>
      <br className="h-4" />
      <div className="px-2 xs:px-6 sm:px-0">
        <CarouselWrapper
          responsiveBreakpoints={responsive}
          infinite
          invertDotColor
          centerMode={dimensions.width > 1024}
          autoPlay
        >
          {recommendationsList.map((rec, i) => {
            return (
              <div className="h-full px-4 pt-4 pb-6 sm:p-6">
                <RecommendationsCard
                  key={i}
                  text={rec.text}
                  author={rec.author}
                />
              </div>
            )
          })}
        </CarouselWrapper>
      </div>
    </Section>
  )
}

import React from "react"
import { Form, JobPost, FormJobPost, FormSmall } from "./Form"
import { Title } from "../atoms/Title"
import { EmailType } from "../../utils/sendEmail"

interface Props {
  title: JSX.Element | string
  text: string | string[] | JSX.Element[]
  jobPost?: JobPost
  url?: string
  type: EmailType
}

export const CallToAction: React.FC<Props> = ({
  title = "title",
  text = "Type your email and lorem ipsum shall happen!",
}) => {
  return (
    <div className="flex flex-col w-full px-6 py-16 sm:py-24 xs:px-10 sm:px-16 bg-vp-blue">
      <div className="flex flex-col sm:items-end sm:flex-row">
        <Title>
          <span className="text-gray-100">{title}</span>
        </Title>
        <div className="max-w-xl mt-4 mb-1 text-sm sm:mr-40">
          {typeof text === "string" ? (
            <p className="leading-snug text-gray-100 sm:ml-6">{text}</p>
          ) : (
            (text as Array<string | JSX.Element>).map((txt: any) => (
              <p key={txt} className="text-gray-100">
                {txt}
              </p>
            ))
          )}
        </div>
      </div>

      <div className="mt-4 sm:mt-10">
        <Form />
      </div>
    </div>
  )
}

export const CallToActionSmall: React.FC<Props> = ({
  title = "title",
  text = "description",
  jobPost,
  url,
  type,
}) => {
  return (
    <div className="flex flex-col w-full p-4 bg-vp-blue">
      <div className="flex flex-col">
        <h1 className="text-3xl font-bold tracking-normal text-gray-100">
          {title}
        </h1>

        <div className="mb-1">
          {typeof text === "string" ? (
            <p className="text-gray-100">{text}</p>
          ) : (
            (text as Array<string | JSX.Element>).map((txt, i) => (
              <p key={i} className="text-gray-100">
                {txt}
              </p>
            ))
          )}
        </div>
      </div>

      <div className="mt-4">
        <FormSmall jobPost={jobPost} url={url} type={type} />
      </div>
    </div>
  )
}

export const CallToActionJobPost: React.FC<Props> = ({
  title = "title",
  text = "description",
  jobPost,
  url,
  type,
}) => {
  return (
    <div className="flex flex-col w-full p-4 bg-vp-blue">
      <div className="flex flex-col">
        <h1 className="text-3xl font-bold tracking-normal text-gray-100">
          {title}
        </h1>

        <div className="mb-1">
          {typeof text === "string" ? (
            <p className="text-gray-100">{text}</p>
          ) : (
            (text as Array<string | JSX.Element>).map((txt, i) => (
              <p key={i} className="text-gray-100">
                {txt}
              </p>
            ))
          )}
        </div>
      </div>

      <div className="mt-4">
        <FormJobPost jobPost={jobPost} url={url} type={type} />
      </div>
    </div>
  )
}
